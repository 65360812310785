<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">My Page<br/>
						<p><span class="prj_ct">[공급기업담당]<nbsp n="2"/><span>{{data.mberNm}}</span> 님 반갑습니다!</span></p>
					</div>
				</div>
				<div class="contents-body tab point sco">
					<div class="my-resume">
						<div class="photo">
							<file-select type="image" v-model="data.imgFile" :src="data.imgSrc" defaultSrc="COMPANY" :name="data.corpNm" @change="corpImgAttach"/>
							기업 대표 이미지로 첨부 바랍니다.<br/>이미지 미첨부시 기본이미지로 저장됩니다.
						</div>

						<div class="mem-box">
							<div class="mem-count">
								<div class="mem">자사소속 인재</div>
								<div class="count">{{asgApprMberCnt.asgMberCnt | number}}명</div>
								<!-- <div class="shortcut" @click="$router.push('SRH401M01')">바로가기 &#9654;</div> -->
							</div>
							<!-- <div class="mem-count">
								<div class="mem">타사소속 인재</div>
								<div class="count">{{asgApprMberCnt.joinAsgMberCnt | number}}명</div>
							</div> -->
							<div class="mem-count">
								<div class="mem">소속 승인요청</div>
								<div class="count">{{asgApprMberCnt.asgApprMberCnt | number}}명</div>
								<span class="noti" v-if="asgApprMberCnt.asgApprMberCnt > 0">!</span>
								<!-- <div class="shortcut" @click="$router.push({name:'PRJ401M01', params: { projectRecrStatus : 1 }})">바로가기 &#9654;</div> -->
							</div>
							<div class="mem-count">
								<div class="mem">소속 퇴사요청</div>
								<div class="count">{{asgApprMberCnt.asgResignMberCnt | number}}명</div>
								<span class="noti" v-if="asgApprMberCnt.asgResignMberCnt > 0">!</span>
								<!-- <div class="shortcut" @click="$router.push({name:'PRJ401M01', params: { projectRecrStatus : 2 }})">바로가기 &#9654;</div> -->
							</div>
							
						</div>
						

						<div class="mem-box last">
							<div class="mem-count">
								<div class="mem">타사소속 인재</div>
								<div class="count">{{asgApprMberCnt.joinAsgMberCnt | number}}명</div>
							</div>
							<div class="mem-count">
							<div class="mem">참여소속 승인요청</div>
								<div class="count">{{asgApprMberCnt.joinAsgApprMberCnt | number}}명</div>
								<span class="noti" v-if="asgApprMberCnt.joinAsgApprMberCnt > 0">!</span>
							</div>
							<div class="mem-count">
								<div class="mem">등록요청 프로젝트</div>
								<div class="count"><span style="cursor : pointer" @click="$router.push({name : 'PRJ406M01'})">{{scoPrjCnt.reqProjectCnt}}건</span></div><span style="font-size : 28px; cursor : pointer;" @click="$router.push({name : 'PRJ406M01'})">&#9654;</span>
								<span class="noti" v-if="scoPrjCnt.reqProjectCnt > 0">!</span>
							</div>
						</div>

						<!-- <img class="people sco" src="/images/people.png" alt="이미지"/> -->
					</div>
				</div>

				<div class="contents-body tab point sco2">
                        <div class="my-resume">                            
                            <div class="mem-box">
                                <div class="title">공급기업 지정 프로젝트</div>
                                <div class="mem-count">
                                    <div class="mem">자사소속 지원인재</div><div class="count">{{scoPrjCnt.scoProjectApplyCnt | number}}명</div>
                                    <span class="noti" v-if="scoPrjCnt.scoProjectApplyCnt > 0">!</span>
                                    <div class="shortcut" @click="$router.push('PRJ402M01')">바로가기 &#9654;</div>
                                </div>
                                <div class="mem-count">
                                    <div class="mem">참여예정</div><div class="count">{{scoPrjCnt.scoProjectBeforeCnt | number}}명</div>
                                    <span class="noti" v-if="scoPrjCnt.scoProjectBeforeCnt > 0">!</span>
									<div class="shortcut" @click="movePage('prj','01')">바로가기 &#9654;</div>
                                </div>
                                <div class="mem-count">
                                    <div class="mem">참여중</div><div class="count">{{scoPrjCnt.scoProjectIngCnt | number}}명</div>
                                    <span class="noti" v-if="scoPrjCnt.scoProjectIngCnt > 0">!</span>
									<div class="shortcut" @click="movePage('prj','02')">바로가기 &#9654;</div>
                                </div>
                            </div>
                            <div class="mem-box last">
                                <div class="title">일반 프로젝트</div>
                                <div class="mem-count">
                                    <div class="mem">자사소속 지원인재</div><div class="count">{{scoPrjCnt.genProjectApplyCnt | number}}명</div>
                                    <span class="noti" v-if="scoPrjCnt.genProjectApplyCnt > 0">!</span>
									<div class="shortcut" @click="$router.push('PRJ403M01')">바로가기 &#9654;</div>
                                </div>
                                <div class="mem-count">
                                    <div class="mem">참여예정</div><div class="count">{{scoPrjCnt.genProjectBeforeCnt | number}}명</div>
                                    <span class="noti" v-if="scoPrjCnt.genProjectBeforeCnt > 0">!</span>
                                    <div class="shortcut" @click="movePage('prj','03')">바로가기 &#9654;</div>
                                </div>
                                <div class="mem-count">
                                    <div class="mem">참여중</div><div class="count">{{scoPrjCnt.genProjectIngCnt | number}}명</div>
                                    <span class="noti" v-if="scoPrjCnt.genProjectIngCnt > 0">!</span>
                                    <div class="shortcut" @click="movePage('prj','04')">바로가기 &#9654;</div>
                                </div>
                            </div>
                        </div>
                    </div>

					<!-- 프로젝트 인터뷰 요청 상태 -->

					
                    <div class="contents-body prj dco mt-0" style="border: 1px solid #808285; margin-bottom: 35px !important;">
                        <div class="title">
                            <img src="/images/interview.png" alt="프로젝트인터뷰요청상태"/>일반 프로젝트 인터뷰 요청 상태
                        </div>

						<template v-if="prjIntvList && prjIntvList.length > 0">
							<template v-for="(item, idx) in prjIntvList">
								<hr v-if="idx != 0" :key="'hr_' + idx"/>
								<div class="prj-box" :key="'project_' + idx">{{item.projectNm}}</div>
								<div class="member" :key="'total_' + idx">
									<p>인터뷰 요청 인재</p>
									<span class="count">총 {{item.interviewTotalCnt | number}}명</span>
								</div>
								<div class="member" :key="'detail_' + idx">
									<p>인터뷰 예정</p>
									<span class="count">{{item.interview01Cnt + item.interview02Cnt | number}}명</span>
									/ 
									<p>인터뷰 합격</p>
									<span class="count">{{item.interview04Cnt | number}}명</span>
									/ 
									<p>인터뷰 불합격</p>
									<span class="count">{{item.interview03Cnt | number}}명</span>
									<div class="shortcut" @click="movePage('intv',item)">바로가기 &#9654;</div>
								</div>
							</template>
						</template>
						<template v-else>
							<div class="prj-box">인터뷰 중인 프로젝트가 없습니다.</div>
						</template>
                    </div>

					<!--자사 소속 기술인재 승인관리-->
					<div class="contents-body prj mypage mt-0" style="margin-bottom: 35px !important;">
						<div class="title"><img src="/images/people_ico.png" alt="프로젝트현황아이콘"/>{{data.corpNm}} 소속 기술인재 승인관리</div>
						<div class="filter">
							<div class="search_box">
								<input type="text" class="search_box int" placeholder="회원명" v-model="srcFilter.srcTxt" @keyup.enter="selectAsgMberAppList('1')"> <div class="search_btn"><img class="search" src="/images/search_btn.png" alt="검색버튼" @click="selectAsgMberAppList('1')"/></div>
							</div>
							<div class="filter_box prj_list">
								<ul>
									<li><img :class="srcFilter.ordCol == 'REG_DATE' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('own', 'REG_DATE')">요청일 순</p><span>ㅣ</span></li>
									<li><img :class="srcFilter.ordCol == 'EMP_DIV_CD' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('own', 'EMP_DIV_CD')">직원구분 순</p></li>
								</ul>
								<div class="state">
									승인상태
									<label for="00"><input type="checkbox" name="state" id="00" v-model="srcFilter.srcApprYn" @change="selectAsgMberAppList('1')">미승인</label>
									<!-- <label for="01"><input type="checkbox" name="state" id="01" value="02" v-model="tempSrcStatList" @change="selectAsgMberAppList()">승인대기</label> -->
								</div>
							</div>
						</div>
						<!-- 소속 승인 요청 기술인재 리스트 -->
						<div class="Board type3">
							<table class="Board_type3 demand">
								<colgroup>
									<col width="4%">
                                    <col width="9%">
                                    <col width="6%">
                                    <col width="8%">
                                    <col width="32">
                                    <col width="32">
                                    <col width="9%">
								</colgroup>
								<thead>
									<tr>
										<th>NO</th>
										<th>회원명</th>
										<th>성별</th>
										<th>직원구분</th>
										<th>소속</th>
										<th>프로젝트 참여소속</th>
										<th>승인요청일</th>
									</tr>
								</thead>
								<tbody v-if="asgMberAppList.length > 0">
									<!-- 최대 10건 조회 -->
									<tr v-for="(row, index) in asgMberAppList" :key="index">
										<td class="score"><div v-if="row.corpApprDiv != '04'" class="flag">미승인</div>{{ (pageInfo.pageIndex - 1) * pageInfo.pageSize + (index + 1)}}</td>
										<td class="score btn_cursor" @click="openPop(row.mberSeq)">{{row.mberNm}} &#9654;</td>
										<td class="score"><SelectComp type="text" list="F:여,M:남" v-model="row.gender" /></td>
										<td class="score">
											<span v-if="row.empDivCd =='' || row.empDivCd == null">-</span>
											<span v-else><SelectComp type="text" cdId="PRO102" v-model="row.empDivCd" /></span>
										</td>
										<td :class="row.corpApprDiv == '01' || row.corpApprDiv == '02'? 'score btn':'score'">
											<span v-if="row.corpApprDiv == '01'"><div class="btn_approve" @click="mberAppr('own', index)">소속 승인요청</div></span>
											<span v-else-if="row.corpApprDiv == '02'"><div class="btn_withdrawal" @click="mberAppr('own', index)">소속 탈퇴요청</div></span>
											<span v-else>{{row.corpNm}}</span>

											<!-- <span v-else-if="row.corpApprDiv == '02'" class="score ap">- 소속 변경 승인대기 -</span>
											<span v-else-if="row.corpApprDiv == '03'"><div class="btn_change" @click="mberAppr('own', index)">소속 변경요청</div></span>
											<span v-else>
												<span v-if="row.corpNm != undefined">{{row.corpNm}}</span>
												<span v-else>-</span>
											</span> -->
										</td>
										<td :class="row.corpApprDiv == '03'? 'score btn':'score'">
											<span v-if="row.corpApprDiv == '03'"><div class="btn_approve" @click="mberAppr('own', index)">참여소속 승인요청</div></span>
											<!-- <span v-else-if="row.joinCorpApprDiv == '02'" class="score ap">- 참여소속 승인대기 -</span>
											<span v-else-if="row.joinCorpApprDiv == '03'"><div class="btn_change" @click="mberAppr('own', index)">참여소속 변경요청</div></span> -->
											<span v-else>
												<span v-if="row.projectJoinCorpNm != undefined">{{row.projectJoinCorpNm}}</span>
												<span v-else>-</span>
											</span>
										</td>
										<td class="score">
											{{row.regDate}}
											<!-- <span v-if="row.corpApprDiv == '04' && row.joinCorpApprDiv == '04'">-</span> -->
											<!-- <span v-if="row.corpApprDiv == '04'">-</span> -->
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<!-- 소속 승인 요청 기술인재가 없을 경우 -->
									<tr>
										<td colspan="7" class="none">소속 승인 요청 기술인재가 없습니다!</td>
									</tr>
								</tbody>
							</table>
						</div>

						<!-- 페이징부분 -->
						<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
					</div>

					<!--타사 소속 기술인재 승인관리-->
					<div class="contents-body prj mypage mt-0" >
						<div class="title"><img src="/images/people_ico.png" alt="프로젝트현황아이콘"/>타사소속 기술인재 승인관리</div>
						<div class="filter">
							<div class="search_box">
								<input type="text" class="search_box int" placeholder="회원명" v-model="otherSrcFilter.srcTxt" @keyup.enter="selectOtherAsgMberAppList('1')"> <div class="search_btn"><img class="search" src="/images/search_btn.png" alt="검색버튼" @click="selectOtherAsgMberAppList('1')"/></div>
							</div>
							<div class="filter_box prj_list">
								<ul>
									<li><img :class="otherSrcFilter.ordCol == 'REG_DATE' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('other', 'REG_DATE')">요청일 순</p><span>ㅣ</span></li>
									<li><img :class="otherSrcFilter.ordCol == 'EMP_DIV_CD' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('other', 'EMP_DIV_CD')">직원구분 순</p></li>
								</ul>
								<div class="state">
									승인상태
									<label for="02"><input type="checkbox" name="state" id="02" value="01,03" v-model="otherSrcFilter.srcApprYn" @change="selectOtherAsgMberAppList('1')">미승인</label>
									<!-- <label for="03"><input type="checkbox" name="state" id="03" value="02" v-model="otherTempSrcStatList" @change="selectOtherAsgMberAppList('1')">승인대기</label> -->
								</div>
							</div>
						</div>
						<!-- 타사 소속 승인 요청 기술인재 리스트 -->
						<div class="Board type3">
							<table class="Board_type3 demand">
								<colgroup>
									<col width="4%">
                                    <col width="9%">
                                    <col width="6%">
                                    <col width="8%">
                                    <col width="32">
                                    <col width="32">
                                    <col width="9%">
								</colgroup>
								<thead>
									<tr>
										<th>NO</th>
										<th>회원명</th>
										<th>성별</th>
										<th>직원구분</th>
										<th>소속</th>
										<th>프로젝트 참여소속</th>
										<th>승인요청일</th>
									</tr>
								</thead>
								<tbody v-if="otherAsgMberAppList.length > 0">
							
									<tr v-for="(row, index) in otherAsgMberAppList" :key="index">
										<td class="score"><div v-if="row.corpApprDiv == '03'" class="flag">미승인</div>{{ (otherPageInfo.pageIndex - 1) * otherPageInfo.pageSize + (index + 1)}}</td>
										<td class="score">{{row.mberNm}}</td>
										<td class="score"><SelectComp type="text" list="F:여,M:남" v-model="row.gender" /></td>
										<td class="score">
											<SelectComp v-if="row.empDivCd" type="text" cdId="PRO102" v-model="row.empDivCd" />
											<span v-else>-</span>
										</td>
										<td :class="row.corpNm != undefined ? 'score':'score np'">
											<span v-if="row.corpNm != undefined">
												{{row.corpNm}}
											</span>
											<span v-else>
												소속없음
											</span>
										</td>
										<td :class="row.corpApprDiv == '03'? 'score btn':'score'">
											<span v-if="row.corpApprDiv == '03'"><div class="btn_approve" @click="mberAppr('other', index)">참여소속 승인요청</div></span>
											<!-- <span v-else-if="row.joinCorpApprDiv == '02'" class="score ap">- 참여소속 승인대기 -</span>
											<span v-else-if="row.joinCorpApprDiv == '03'"><div class="btn_change" @click="mberAppr('other', index)">참여소속 변경요청</div></span> -->
											<span v-else>
												<span v-if="row.projectJoinCorpNm != undefined">{{row.projectJoinCorpNm}}</span>
												<span v-else>-</span>
											</span>
										</td>
										
										<td class="score">
											<span v-if="row.corpApprDiv != '03'">-</span>
											<span v-else>{{row.regDate}}</span>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<!-- 타사 소속 승인 요청 기술인재가 없을 경우 -->
									<tr>
										<td colspan="7" class="none">타사소속 승인 요청 기술인재가 없습니다!</td>
									</tr>
								</tbody>
							</table>
						</div>

						<!-- 페이징부분 -->
						<pagingComp :pageInfo="otherPageInfo" @page-click="otherGoPage"/>
					</div>

				<!-- 마이페이지 하단 배너 부분 -->
				<div class="mypage-banner">
					<div class="tit">하이프로만의 특별함!</div>
					<div class="banner">
						<img src="/images/mypage_bn01.png" alt="마이페이지배너1"/>
						<p @click="$router.push({name:'MAN001M01', query:{'target':'infoPoint'}})">자세히 보기 &#9654;</p>
					</div>
					<div class="banner">
						<img src="/images/mypage_bn02.png" alt="마이페이지배너2"/>
						<p @click="$router.push({name:'MAN001M01', query:{'target':'infoSafety'}})">자세히 보기 &#9654;</p>
					</div>
					<div class="banner">
						<img src="/images/mypage_bn03.png" alt="마이페이지배너3"/>
						<p @click="$router.push({name:'MAN001M01', query:{'target':'infoAS'}})">자세히 보기 &#9654;</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import fileSelect from "@/components/FileSelect.vue";
import Nbsp from '../../../components/base/nbsp.vue';
import pagingComp from '@/components/PagingComp.vue';

export default {

	components : { fileSelect, Nbsp, pagingComp },

	data() {
		return {
			data: {
				corpSeq :''
			},

			asgApprMberCnt : {},
			scoPrjCnt : {},
			prjIntvList : [],
			corpdiv : this.$store.state.userInfo.mberDivCd,

			// 자사
			asgMberAppList : [],
			pageInfo : {},
			srcFilter : {
				pageIndex : '1',
				ordCol : 'REG_DATE',
				//srcStatList : [],
				srcTxt : '',
				srcApprYn : ''
			},
			//tempSrcStatList : [],

			// 타사
			otherAsgMberAppList : [],
			otherPageInfo : {},
			otherSrcFilter : {
			 	pageIndex : '1',
			 	ordCol : 'REG_DATE',
			 	//srcStatList : [],
			 	srcTxt : '',
				srcApprYn : ''
			 },
			otherTempSrcStatList : [],

		};
	},
	mounted(){
		this.$.httpPost('/api/mem/myp/getScoCorpInfo')
			.then(res => {
				this.data = res.data.scoCorpInfo;
				this.scoPrjCnt = res.data.scoPrjCnt;
				this.asgApprMberCnt = res.data.asgApprMberCnt;
				this.data.imgSrc = '/api/mem/myp/corpImageDown?corpSeq=' + this.data.corpSeq;

				this.data.corpSeq = res.data.scoCorpInfo.corpSeq;

				this.selectAsgMberAppList();
				this.selectOtherAsgMberAppList();
			});

		this.$.httpPost('/api/prj/prj/getInterviewProject', {corpdiv : this.corpdiv})
			.then(res => {
				this.prjIntvList = res.data.list;
			});	
			
	},

	methods: {
		// 기업 이미지 첨부
		corpImgAttach() {
			if(this.data.imgFile) {
				this.$.fileUpload('IMG', this.data.imgFile, '/api/mem/myp/corpImageAttachment', this.data)
					.then(() => {
						this.data.imgFile = null;
						this.data.imgSrc = '/api/mem/myp/corpImageDown?corpSeq=' + this.data.corpSeq;
					});
			}
		},

		movePage(div,item) {
			var params = {}
			if(div == 'intv'){
				params.projectSeq = item.projectSeq;
				params.projectNm = item.projectNm;
				params.caller = { name: this.$route.name};
			
				this.$router.push({name: 'PRJ404M02', params });
			}else{
				var projectDivCd = '3'
				var projectRecrStatus = '2'

				if(item == '03' || item == '04'){ projectDivCd = '1' }
				if(item == '02' || item == '04'){ projectRecrStatus = '1' }
	
				params.projectDivCd = projectDivCd;
				params.projectRecrStatus = projectRecrStatus;
				params.caller = { name: this.$route.name }

				this.$router.push({name: 'PRJ401M03', params });
				// if(item == '03' || item == '04'){ 
				// 	this.$router.push({name: 'PRJ401M02', params });
				//  } else {
				// 	this.$router.push({name: 'PRJ401M01', params });
				//  }
			}
  
        },

		// 소속 기술인재 승인 리스트
		selectAsgMberAppList(div){

			// if(this.tempSrcStatList.length > 0 ){
			// 	var tempString = this.tempSrcStatList.toString();
			// 	this.srcFilter.srcStatList = tempString.split(',');
			// }else{
			// 	this.srcFilter.srcStatList =[]
			// }
			
			var param = this.srcFilter;
			param.corpSeq = this.data.corpSeq;
			param.pageUnit = '10';
			param.pageSize = '10';
			if(div) param.pageIndex = 1;

			//param.asgMberListDiv='own';

			this.$.httpPost('/api/mem/myp/selectAsgMberAppList', param)
			.then(res => {
				this.asgMberAppList = res.data.asgMberAppList;
				this.pageInfo = res.data.pageInfo;
			});

		},

		// 타사 소속 기술인재 승인 리스트
		selectOtherAsgMberAppList(div){

			// if(this.otherTempSrcStatList.length > 0 ){
			// 	var tempString = this.otherTempSrcStatList.toString();
			// 	this.otherSrcFilter.srcStatList = tempString.split(',');
			// }else{
			// 	this.otherSrcFilter.srcStatList =[]
			// }
			
			var param = this.otherSrcFilter;
			param.corpSeq = this.data.corpSeq;
			param.pageUnit = '10';
			param.pageSize = '10';
			if(div) param.pageIndex = 1;

			param.asgMberListDiv='other';

			this.$.httpPost('/api/mem/myp/selectAsgMberAppList', param)
			.then(res => {
				this.otherAsgMberAppList = res.data.asgMberAppList;
				this.otherPageInfo = res.data.pageInfo;
			});

		},

		// 소속 승인관련 기술인재 수
		selectAsgApprMberCnt(){
			this.$.httpPost('/api/mem/myp/selectAsgApprMberCnt', {corpSeq : this.data.corpSeq})
			.then(res => {
				this.asgApprMberCnt = res.data;
			});
		},

		// 기술인재 소속 승인하기
		mberAppr(div, index) {
			var obj =  {};

			if(div == 'other'){
				obj = this.otherAsgMberAppList[index];
			}
			else{
				obj =  this.asgMberAppList[index];
			}

			var params = {
				mberNm:obj.mberNm,
				gender:obj.gender,
				empDivCd:obj.empDivCd,

				classDivCd : obj.classDivCd,
				corpApprDivCd  : obj.corpApprDiv,
				aftEmpDivCd : obj.aftEmpDivCd,
				aftCorpNm : obj.aftCorpNm || '소속없음',

				//joinCorpApprDivCd  : obj.joinCorpApprDiv,
				//preCorpNm : obj.preCorpNm || '소속없음',
				//asgDiv : obj.asgDiv
			};

			this.$.popup('/sco/myp/MYP401P01', params)
				.then(res => {
					if(res.flag) {
						var param = {
							apprMberSeq : obj.mberSeq,
							empDivCd:obj.empDivCd,

							mberAsgHistorySeq :obj.mberAsgHistorySeq,
							classDivCd : obj.classDivCd,
							//asgDiv : obj.asgDiv,
							aftEmpDivCd : obj.aftEmpDivCd,
							aftCorpSeq : obj.aftCorpSeq, 
							aftCorpNm : obj.aftCorpNm || '소속없음',
						};

						param.aftAsgApprYn = res.apprYn;

						// if(obj.asgDiv == 'PRE'){
						// 	param.preAsgApprYn = res.apprYn;
	
						// }else{
						// 	param.aftAsgApprYn = res.apprYn;
						// }
					
						this.$.httpPost('/api/mem/myp/updateAsgMberAppInfo', param)
							.then(() => {
								this.selectAsgMberAppList();

								if(div == 'own'){
									this.selectAsgMberAppList();
								}
								else{
									this.selectOtherAsgMberAppList();
								}
								this.selectAsgApprMberCnt();
							}).catch(this.$.httpErrorCommon);
					}
				})
		},
		// 기술인재 개인정보 팝업
		openPop(seq) {
			var div = 'tec';
			this.$.popup('/dco/gmg/myp/MYP201P01', { seq, div });
		},

		// sort 변경
		searchOrder(div, col) {
			
			if(div == 'own'){
				this.srcFilter.ordCol = col;
				this.selectAsgMberAppList();
			}
			else{
				this.otherSrcFilter.ordCol = col;
				this.selectOtherAsgMberAppList();
			}
		},

		// 자사소속 페이징 이동
		goPage(page) {
			this.srcFilter.pageIndex = page;
			this.selectAsgMberAppList();
		},

		// 타사소속 페이징 이동
		otherGoPage(page) {
			this.otherSrcFilter.pageIndex = page;
			this.selectOtherAsgMberAppList();
		},
	},
};
</script>
